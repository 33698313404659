import {Box, Button, withStyles} from '@material-ui/core';
import React, {useCallback, useEffect, useReducer, useState} from 'react';
import Collapsible from 'react-collapsible';

import elasticSearchReducer, {initialState} from '../../reducers/elasticSearchReducer';
import * as ActionCreators from '../../store/actioncreators';
import autodispatch from '../../store/autodispatch';
import {format} from 'date-fns';

const styles = () => ({
    stickyRight: {
        marginLeft: 'auto',
    },
    timeBox: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        color: '#444',
        marginRight: 5,
        padding: '0 5px',
        borderRadius: '0 10px 10px 0',
    },
    collapsible: {
        wordBreak: 'break-all',
        '&:nth-child(even)': {
            backgroundColor: '#7c7c7c',
        },
        '&:nth-child(odd)': {
            backgroundColor: '#616161',
        },
    },
    triggerBar: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr 1fr 7fr',
        marginLeft: '-6px',
    },
    openTrigger: {
        backgroundColor: '#FF7519',
    },
    auditLogContentBox: {
        margin: 5,
    },
    keyBox: {
        fontWeight: 'bold',
        padding: 5,
        backgroundColor: '#efefef',
    },
    twoFrameGrid: {
        display: 'grid',
        padding: 5,
        gridTemplateColumns: '1fr 1fr',
    },
    fourFrameGrid: {
        display: 'grid',
        padding: 5,
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    codeBox: {
        padding: 5,
        width: 300,
    },
});

function AuditLogOverzicht(props) {
    const {endpoint, filter, version, footer, classes, setMaxLogSize, maxLogSize, increaseLogBy} = props;
    const [state, dispatch] = useReducer(elasticSearchReducer, initialState);
    const [openIndex, setOpenIndex] = useState(null);
    const {results} = state;
    const {fetchData} = autodispatch(ActionCreators, dispatch);

    const formatData = (results) => results.toJS();

    const doFetch = useCallback(() => {
        if (version > 0) {
            fetchData({endpoint, filter});
        }
    }, [endpoint, version]);

    useEffect(() => {
        doFetch();
    }, [endpoint, version]);

    const convertRequest = (request) => {
        if (request?.put) {
            const put = request.put;
            return (
                <Box>
                    <Box className={classes.keyBox}>PUT</Box>
                    <Box className={classes.twoFrameGrid}>
                        <Box>
                            <b>Database ID</b>
                            <Box>{put.info.id}</Box>
                        </Box>
                        <Box>
                            <b>Object type</b>
                            <Box>{put.info.objecttype}</Box>
                        </Box>
                    </Box>
                    <Box className={classes.codeBox}>
                        <b>Difference</b>
                        <pre>
                            <code>{JSON.stringify(put.difference, undefined, 2)}</code>
                        </pre>
                    </Box>
                </Box>
            );
        }

        if (request?.post) {
            return (
                <Box>
                    <Box className={classes.keyBox}>
                        POST {request.path}
                    </Box>
                    <Box className={classes.codeBox}>
                        <pre>
                            <code>{JSON.stringify(request.post, undefined, 2)}</code>
                        </pre>
                    </Box>
                </Box>
            );
        }

        if (request?.delete) {
            return (
                <Box>
                    <Box className={classes.keyBox}>DELETE</Box>
                    <Box className={classes.twoFrameGrid}>
                        <Box>
                            <b>Database ID</b>
                            <Box>{request.delete.info.id}</Box>
                        </Box>
                        <Box>
                            <b>Object type</b>
                            <Box>{request.delete.info.objecttype}</Box>
                        </Box>
                    </Box>
                    <Box className={classes.codeBox}>
                        <pre>
                            <code>{JSON.stringify(request.delete, undefined, 2)}</code>
                        </pre>
                    </Box>
                </Box>
            );
        }
        return (
            <Box>
                <Box className={classes.key}>Request type not supported</Box>
                <pre>
                    <code>{JSON.stringify(request, undefined, 2)}</code>
                </pre>
            </Box>
        );
    };

    const findTheObjectType = (result) => {
        let objectType = '';

        if (result.request.delete) {
            objectType = result.request.delete.info.objecttype;
        } else if (result.request.put) {
            objectType = result.request.put.info.objecttype;
        } else if (result.request.post) {
            objectType = result.request.post.info?.objecttype;
        }

        return objectType;
    };

    const handleTriggerClick = (index) => {
        // If the same index is clicked, close it, otherwise open the new one
        setOpenIndex(openIndex === index ? null : index);
    };

    const isDateValid = (dateStr) => {
        return !isNaN(new Date(isNaN(dateStr) ? dateStr : parseInt(dateStr)));
    }

    return (
        <Box display={'flex'} flexGrow={1} flexDirection={'column'}>
                {results.size > 0 ? (
                    formatData(results).map((result, index) => (
                        <Collapsible
                            trigger={
                                <Box className={classes.triggerBar}>
                                    <Box
                                        className={classes.timeBox}>{isDateValid(result.requested_on) ?
                                        format(new Date(isNaN(result.requested_on) ? result.requested_on : parseInt(result.requested_on)), 'yyyy-MM-dd HH:mm:ss')
                                        :
                                        result.requested_on}
                                    </Box>
                                    <Box>{result.account_name}</Box>
                                    <Box>{result.request_type}</Box>
                                    <Box>{findTheObjectType(result)}</Box>
                                    <Box>{result.url}</Box>
                                </Box>
                            }
                            key={index}
                            className={classes.collapsible}
                            triggerOpenedClassName={classes.openTrigger}
                            transitionTime={200}
                            classParentString={'Collapsible'}
                            onTriggerOpening={() => handleTriggerClick(index)}
                            open={openIndex === index}
                        >
                            <Box className={classes.twoFrameGrid}>
                                <Box className={classes.auditLogContentBox}>
                                    <Box className={classes.keyBox}>requested_on</Box>
                                    <Box>{isDateValid(result.requested_on) ? format(new Date(isNaN(result.requested_on) ? result.requested_on : parseInt(result.requested_on)), 'yyyy-MM-dd HH:mm:ss') : result.requested_on}</Box>
                                </Box>
                                <Box className={classes.auditLogContentBox}>
                                    <Box className={classes.keyBox}>signed_in_on</Box>
                                    {<Box>{isDateValid(result.signed_in_on) ? format(new Date(isNaN(result.signed_in_on) ? result.signed_in_on : parseInt(result.signed_in_on)), 'yyyy-MM-dd HH:mm:ss') : result.signed_in_on}</Box>}
                                </Box>
                            </Box>
                            <Box className={classes.twoFrameGrid}>
                                {Object.keys(result)
                                    .filter(
                                        (k) =>
                                            !['request_type', 'account_name', 'request', 'warning', 'auth_role', 'requested_on', 'signed_in_on'].includes(
                                                k,
                                            ),
                                    )
                                    .map((k) => (
                                        <Box className={classes.auditLogContentBox}>
                                            <Box className={classes.keyBox}>{k}</Box>
                                            <Box>{JSON.stringify(result[k])}</Box>
                                        </Box>
                                    ))}
                            </Box>
                            <Box className={classes.auditLogContentBox}>
                                <Box className={classes.keyBox}>Rechten van de beheerder</Box>
                                <Box className={classes.fourFrameGrid}>
                                    {result.auth_role.rechten?.map((r) => (
                                        <Box>{r}</Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box className={classes.auditLogContentBox}>{convertRequest(result.request)}</Box>
                            <Box className={classes.auditLogContentBox}>
                                <Box className={classes.keyBox}>Warning</Box>
                                <Box>{result.warning}</Box>
                            </Box>
                        </Collapsible>
                    ))
                ) : (
                    <div>Geen activiteit gevonden</div>
                )}
                <span className={classes.expandArrow}>{footer(state)}</span>
            {maxLogSize && increaseLogBy && results.size === maxLogSize && (
                <Button
                    className={classes.stickyRight}
                    color='primary'
                    variant='outlined'
                    onClick={() => {
                        setMaxLogSize(maxLogSize + increaseLogBy);
                    }}
                >
                    <i className='material-icons'>expand_more</i>
                </Button>
            )}
        </Box>
    );
}

export default withStyles(styles)(AuditLogOverzicht);
